/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
//❗ - Thus function should be replace by pinia userStore
import axiosIns from '@axios'

export const isUserLoggedIn = async () => {
  const response = await axiosIns.get('/auth/is-authenticated')

  return response.data.isAuthenticated
}
