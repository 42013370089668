import posthog from "posthog-js";

export default posthog.init(
      import.meta.env.VITE_POSTHOG_TOKEN || "__VITE_POSTHOG_TOKEN__",
      {
        api_host: import.meta.env.VITE_POSTHOG_HOST || "__VITE_POSTHOG_HOST__",
        capture_pageview: false,
        person_profiles: 'identified_only',
      }
    );
