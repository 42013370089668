/* eslint-disable import/no-unresolved */
import posthogIns from "@/plugins/posthog"
import { canNavigate } from "@layouts/plugins/casl"
import { useSubscriptionStore } from "@store/subscription"
import { useUserStore } from "@store/user"
import { setupLayouts } from "virtual:generated-layouts"
import { createRouter, createWebHistory } from "vue-router"
import routes from "~pages"
import { isUserLoggedIn } from "./utils"

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL || "__BASE_URL__"),
  routes: [
    {
      path: "/",
      redirect: to => {
        const userStore = useUserStore()

        const subscriptionStore = useSubscriptionStore()

        const userData = userStore?.userData

        const isSubscribed = subscriptionStore?.data?.isSubscribed
          ? false
          : true

        if (isSubscribed && userData.type)
          return { name: "subscription-paywall" }
        if (userData.type) return { name: "dashboards" }

        return { name: "authentication-login", query: to.query }
      },
    },
    {
      path: "/user/settings",
      redirect: () => ({
        name: "user-settings-tab",
        params: { tab: "account" },
      }),
    },
    ...setupLayouts(routes),
  ],
})

// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
router.beforeEach(async (to, from, next) => {
  const isLoggedIn = await isUserLoggedIn()
  const userStore = useUserStore()
  const subscriptionStore = useSubscriptionStore()
  if (!["Demo", "Landing", "Auth"].includes(to.meta.subject)) {
    if (Object.keys(userStore.userData).length === 0) {
      await userStore.fetchUserData()
    }
    await subscriptionStore.isSubscribed()
  }

  const isEmailVerified = userStore.userData.email_verified

  const isSubscribed = subscriptionStore.data.isSubscribed

  if (await canNavigate(to)) {
    if (["Demo", "Landing", "Auth"].includes(to.meta.subject)) {
      return next()
    }
    if (
      (to.meta.redirectIfLoggedIn && isLoggedIn) ||
      (to.meta.redirectIfEmailVerified && isEmailVerified) ||
      (to.meta.redirectIfSubscribed && isSubscribed)
    ) {
      return next("/")
    } else if (!to.meta.redirectIfEmailVerified && isEmailVerified === false) {
      return next({ name: "authentication-verify-email" })
    } else if (
      !to.meta.redirectIfSubscribed &&
      isSubscribed === false &&
      isLoggedIn
    ) {
      return next({ name: "subscription-paywall" })
    }
  } else {
    if (isLoggedIn) {
      return next({ name: "not-authorized" })
    } else {
      return next({
        name: "authentication-login",
        query: { to: to.name !== "index" ? to.fullPath : undefined },
      })
    }
  }

  next()
})

router.afterEach((to, from, failure) => {
  if (!failure) {
    nextTick(() => {
      posthogIns.capture("$pageview", { path: to.fullPath })
    })
  }
})

export default router
