// src/stores/useConsentStore.js
import { defineStore } from 'pinia';

export const useListenersStore = defineStore('listeners', {
  state: () => ({
    consentListeners: [],
  }),
  actions: {
    addConsentListener(listener) {
      this.consentListeners.push(listener);
    },
    removeConsentListener(listener) {
      const index = this.consentListeners.indexOf(listener);
      if (index !== -1) {
        this.consentListeners.splice(index, 1);
      }
    },
    clearConsentListeners() {
      this.consentListeners = [];
    },
    onConsentChange() {
      this.consentListeners.forEach((listener) => listener());
    }
  },
});
