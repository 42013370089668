const countryColors = {
  AO: '#63321A',
  CI: '#65341C',
  KM: '#67351D',
  GH: '#69361E',
  NA: '#6B3820',
  NG: '#6D3A21',
  TN: '#6F3B22',
  BI: '#713D24',
  BF: '#733E25',
  GQ: '#754026',
  LR: '#774227',
  LY: '#794429',
  ML: '#7B452A',
  MR: '#7D472B',
  MW: '#7F482D',
  NE: '#814A2E',
  RW: '#834C2F',
  SN: '#854D31',
  SO: '#874F32',
  SS: '#895133',
  SZ: '#8B5235',
  TG: '#8D5436',
  ZM: '#8F5537',
  BW: '#915739',
  CF: '#93583A',
  CM: '#955A3B',
  CG: '#975C3D',
  DJ: '#995D3E',
  ET: '#9B5F3F',
  GA: '#9D6041',
  GN: '#9F6242',
  GM: '#A16343',
  GW: '#A36545',
  KE: '#A56646',
  LS: '#A76847',
  MZ: '#A96949',
  SL: '#AB6B4A',
  ST: '#AD6C4B',
  TD: '#AF6E4D',
  TZ: '#B1704E',
  UG: '#B3714F',
  ZW: '#B57351',
  ZA: '#B77552',
  BJ: '#B97654',
  CD: '#BB7855',
  CV: '#BD7956',
  DZ: '#BF7B58',
  ER: '#C17D59',
  EH: '#C37E5B',
  MA: '#C5805C',
  MG: '#C7825D',
  SD: '#C9845E',
  TF: '#800080',
  RE: '#800080',
  MU: '#800080',
  IO: '#E6F1F8',
  YT: '#800080',
  SH: '#E6F1F8',
  SC: '#800080',
  AX: '#FF4500',
  AL: '#B3D9E8',
  AD: '#00FFFF',
  AM: '#7EBFDB',
  BE: '#0000FF',
  CH: '#4BA6CD',
  DE: '#FF0000',
  ES: '#00FFFF',
  EE: '#FF1493',
  FI: '#FF4500',
  FO: '#002E5D',
  GB: '#E6F1F8',
  GI: '#E6F1F8',
  HR: '#1B8CBD',
  IT: '#00CED1',
  LI: '#006FA3',
  LV: '#FFFF00',
  MD: '#005F92',
  RO: '#004E80',
  SM: '#003E6F',
  AZ: '#000D3A',
  BG: '#0099CC',
  BY: '#66C2FF',
  JE: '#E6F1F8',
  GG: '#E6F1F8',
  GR: '#FFD700',
  HU: '#FF4500',
  IS: '#3F7A7A',
  LT: '#FF6347',
  MK: '#598F8F',
  MT: '#FFA500',
  SK: '#FF00FF',
  SE: '#00FF00',
  UA: '#72A3A3',
  BA: '#8CB8B8',
  ES: '#00FFFF',
  IM: '#E6F1F8',
  ME: '#A6CCCC',
  NO: '#EBF5FF',
  PL: '#FF0000',
  PT: '#FF8C00',
  RU: '#266666',
  SJ: '#EBF5FF',
  RS: '#C0E0E0',
  AT: '#00FF00',
  CZ: '#FFD700',
  DK: '#002E5D',
  ES: '#00FFFF',
  FR: '#800080',
  GB: '#E6F1F8',
  GB: '#E6F1F8',
  GE: '#CCEBFF',
  IE: '#9400D3',
  LU: '#008000',
  MC: '#800080',
  NL: '#8A2BE2',
  PT: '#FF8C00',
  SI: '#000080',
  VA: '#00CED1',
  BM: '#E6F1F8',
  CA: '#cb89cb',
  PM: '#800080',
  US: '#cc96cc',
  US: '#cc96cc',
  US: '#cc96cc',
  GL: '#e5bae5',
  BD: '#FFCC33',
  BT: '#FFCD3A',
  JP: '#FFCE41',
  MO: '#FFD46B',
  NP: '#FFD04F',
  PK: '#FFD156',
  PH: '#FFD25D',
  TL: '#FFD364',
  TW: '#FFD46B',
  CN: '#FFD46B',
  ID: '#FFD572',
  IN: '#FFD679',
  KZ: '#FFD780',
  KG: '#FFD887',
  MM: '#FFD500',
  MY: '#FFDA95',
  MY: '#FFDB9C',
  AF: '#FFDCA3',
  HK: '#FFD46B',
  KH: '#FFDDAA',
  KR: '#FFDEB1',
  SG: '#FFDFB8',
  TM: '#FFE0BF',
  UZ: '#FFE1C6',
  VN: '#FFE2CD',
  BH: '#FFE3D4',
  BN: '#FFE4DB',
  LA: '#FFE5E2',
  LK: '#FFE6E9',
  MV: '#FFE7F0',
  MN: '#FFE8F7',
  KP: '#FFFF00',
  TH: '#FFCF48',
  TJ: '#FFD98E',
  AR: '#FF9999',
  AG: '#FF9FA3',
  CU: '#FFA9AD',
  KY: '#FFB3B7',
  DO: '#FFE5DD',
  GP: '#800080',
  GT: '#FFEFE3',
  GF: '#800080',
  JM: '#FFF9E7',
  KN: '#FFFFEB',
  PA: '#FF11C2',
  SR: '#FFF9F0',
  TC: '#E6F1F8',
  UY: '#FFF3F4',
  BZ: '#FFEDEF',
  CW: '#8A2BE2',
  EC: '#FFD7EA',
  MX: '#FFC1E6',
  MS: '#E6F1F8',
  MQ: '#800080',
  PZ: '#FF11C2',
  PE: '#FFABE1',
  PR: '#FF95DD',
  PY: '#FF7FD8',
  GS: '#E6F1F8',
  SV: '#FF69D4',
  SX: '#8A2BE2',
  AI: '#E6F1F8',
  AN: '#8A2BE2',
  BQ: '#8A2BE2',
  BL: '#800080',
  BO: '#FF53CF',
  BB: '#FF3DCB',
  BV: '#EBF5FF',
  CO: '#FFBDC1',
  CR: '#FFC7CB',
  KN: '#FFD1D5',
  LC: '#FFDBD9',
  NI: '#FF27C6',
  VC: '#FF06BD',
  AW: '#FF0FD2',
  BS: '#FF1EE7',
  BR: '#FFCCFF',
  BR: '#FFCCFF',
  CL: '#FF2DFC',
  DM: '#FF3AEE',
  FK: '#E6F1F8',
  GD: '#FF48E0',
  GY: '#FF55D2',
  HN: '#FF63C4',
  HT: '#FF70B6',
  MF: '#800080',
  TT: '#FF7EA8',
  VE: '#FF8B99',
  VG: '#E6F1F8',
  VI: '#cc96cc',
  AE: '#99FFCC',
  CY: '#9AFED1',
  EG: '#9BFDD6',
  IL: '#9CFCD9',
  PS: '#9DFBDE',
  IR: '#9EFBE3',
  OM: '#9FFAE8',
  SA: '#A0F9ED',
  TR: '#A1F8F2',
  JO: '#A2F7F7',
  SY: '#A3F6FC',
  YE: '#A4F5FF',
  AE: '#A5EFFF',
  IQ: '#A6DEFF',
  KW: '#A7CDFF',
  LB: '#A8BCFF',
  QA: '#99FF66',
  AU: '#FF00FF',
  NR: '#FD00F2',
  PF: '#800080',
  TK: '#FB00E6',
  UM: '#cc96cc',
  VU: '#F900D9',
  CX: '#F700CC',
  HM: '#F500BF',
  KI: '#F300B3',
  MH: '#F100A6',
  NC: '#EF0099',
  NF: '#ED008C',
  NU: '#EB007F',
  NZ: '#E90073',
  UM: '#cc96cc',
  CK: '#E70066',
  FJ: '#E50059',
  GU: '#E3004D',
  SB: '#E10040',
  WF: '#800080',
  WS: '#DF0033',
  UM: '#cc96cc',
  UM: '#DD0026',
  AS: '#cc96cc',
  CC: '#FF00FF',
  FM: '#DB001A',
  MP: '#cc96cc',
  PN: '#D9000D',
  PW: '#D70000',
  PG: '#D40000',
  TO: '#D20000',
  TV: '#CC0066',
  AQ: '#808080',
  NorthAmerica: '#901090',
  LatinAmerica: '#eda6c4',
  Africa: '#5b3c11',
  Asia: '#f4cf0f',
  Oceania: '#f52929',
  MiddleEast: '#069338',
  WestEurope: '#cc96cc',
  EastEurope: '#0a3fdd',
}

export default countryColors
