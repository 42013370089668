import { createMongoAbility, defineAbility } from "@casl/ability"
import { useUserStore } from "@store/user"

export const initialAbility = [
  {
    action: "read",
    subject: "Auth",
  },
  {
    action: "read",
    subject: "Landing",
  },
  {
    action: "read",
    subject: "Demo",
  },
]

export function buildAbilities(role) {
  return defineAbility((can, cannot) => {
    if (role.PermsSuperAdmin) {
      can("manage", "all")
    }
    if (role.PermsAdmin) {
      can("manage", "all")
    }
    if (role.PermsUser) {
      can("manage", "all")
    }
  }).rules
}

//  Read ability from localStorage
// 👉 Handles auto fetching previous abilities if already logged in user
// ℹ️ You can update this if you store user abilities to more secure place
// ❗ Anyone can update localStorage so be careful and please update this

export const ability = createMongoAbility(initialAbility)

export async function updateAbilities() {
  const userStore = useUserStore()

  await userStore.fetchAbilities()

  ability.update(userStore.abilities)
}
