import axiosIns from '@axios'
import { useUserStore } from '@store/user'
import { defineStore } from 'pinia'

export const useSubscriptionStore = defineStore('Subscription', {
  state: () => ({
    data: {
      isSubscribed: false,
    },
  }),
  actions: {
    async getSubscriptionID() {
      try {
        const response = await axiosIns.get('/user/subscription')
        return response.data.StripeSubscriptionId
      } catch (error) {
        console.error('Error getting subscription ID:', error)
        return null
      }
    },
    async isSubscribed() {
      try {
        const userStore = useUserStore()
        if (
          userStore.userData.type === 'Super Administrateur' ||
          userStore.userData.type === 'Test User' ||
          (import.meta.env.VITE_PRODUCTION_MODE ||
            '__VITE_PRODUCTION_MODE__') === 'FALSE'
        ) {
          this.data.isSubscribed = true
          console.log(
            'User is a ' + userStore.userData.type + ' : Paywall Bypass'
          )
          return true
        }

        // Générer un nouveau token
        const subId = await this.getSubscriptionID()

        if (!subId) {
          console.error('Subscription ID not found')
          return false
        }

        // Requête pour obtenir les détails de l'abonnement
        const response = await fetch(
          `${
            import.meta.env.VITE_STRIPE_ENDPOINT || '__VITE_STRIPE_ENDPOINT__'
          }subscriptions/${subId}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${
                import.meta.env.VITE_STRIPE_SECRET_KEY ||
                '__VITE_STRIPE_SECRET_KEY__'
              }`,
              'Content-Type': 'application/json',
            },
          }
        )

        // Vérification de la réponse
        if (response.status !== 200) {
          console.log('Returning false due to unsuccessful response')
          return false
        }

        const data = await response.json()

        // Mise à jour de l'état de l'abonnement et retour d'un booléen
        const isActive = data.status === 'active'
        this.data.isSubscribed = isActive
        return isActive
      } catch (error) {
        console.error('Error fetching subscription details:', error)
        console.log('Returning false due to error')
        return false
      }
    },
  },
})
