import axiosIns from "@axios"
import { buildAbilities } from "@plugins/casl/ability"
import { defineStore } from "pinia"

export const useUserStore = defineStore("user", {
  state: () => ({
    abilities: [
      { action: "read", subject: "Auth" },
      {
        action: "read",
        subject: "Landing",
      },
      {
        action: "manage",
        subject: "Demo",
      },
    ],
    userData: {},
  }),
  actions: {
    async fetchAbilities() {
      try {
        if (this.userData.id) {
          const response = await axiosIns.get("/user/role")

          // Construisez les abilités en fonction des rôles et mettez à jour le state
          this.abilities = buildAbilities(response.data.role)
        } else {
          this.abilities = [
            { action: "read", subject: "Auth" },
            { action: "read", subject: "Landing" },
            {
              action: "read",
              subject: "Demo",
            },
          ]
        }
      } catch (error) {
        this.abilities = [
          { action: "read", subject: "Auth" },
          { action: "read", subject: "Landing" },
          {
            action: "read",
            subject: "Demo",
          },
        ]
      }
    },
    async fetchUserData() {
      try {
        const response = await axiosIns.get("/user/info")

        this.userData = response.data
      } catch (error) {
        this.userData = {}
      }
    },
    async fetchEmail() {
      try {
        const response = await axiosIns.get("/user/email")

        return response.data.email
      } catch (error) {
        console.error("Error fetching email:", error)

        return null
      }
    },
    async fetchPhone() {
      try {
        const response = await axiosIns.get("/user/phone")

        return response.data.phone
      } catch (error) {
        console.error("Error fetching phone:", error)

        return null
      }
    },
  },
  persist: {
    storage: sessionStorage,
  },
})
